import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function Privacy() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      {/* <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
            This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more.
          </h5>
        </GridItem>
      </GridContainer> */}
      <GridContainer>
          <GridItem xs={12} sm={12} md={1}></GridItem>
          <GridItem xs={12} sm={12} md={10}>
            {/* ----------------------- */}

            <div style={{ marginTop: 40, textAlign: "left", color: "#000000" }}>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <h5 className={classes.title}>Privacy Policy</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <p className={classes.description}>
                  This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our software.
                  </p>
                </GridItem>
              </GridContainer>

              {/* ------------------------- */}
              <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                  <h5 className={classes.title}>
                    {/*  */}
                  </h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                  <p className={classes.description}>
                    {/*  */}
                  </p>
              </GridItem>
              </GridContainer>
              {/* ------------------------- */}

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      What personal information do we collect from the people that uses our app?
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                      <p>
                      When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number, Contacts and other data or other details to help you with your experience.
                      </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      When do we collect information?
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                    <p>
                    We collect information from you when you register on our app, use Live Chat and or upload data to our servers.
                    </p>
                    <p>
                        The use of VOIP call features.
                    </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      How do we use your information?
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                    We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, use the app, or use certain other app features in the following ways:
                        <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                        <li>To improve our app in order to better serve you.</li>
                        <li>To allow us to better service you in responding to your customer service requests.</li>
                    </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      How do we protect your information?
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                    We do not use vulnerability scanning and/or scanning to PCI standards.
                                </p>
                                <p>
                                    We only provide articles and information. We never ask for credit card numbers.
                                </p>
                                <p>
                                    We use regular Malware Scanning.
                                </p>
                                <p>
                                    Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
                                </p>
                                <p>
                                    We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.
                                </p>
                                <p>
                                    All transactions are processed through a gateway provider and are not stored or processed on our servers.
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Do we use 'cookies'?
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                    We do not use cookies for tracking purposes
                                </p>
                                <p>
                                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.
                                </p>
                                <p>
                                    If you turn cookies off, Some of the features that make your site experience more efficient may not function properly that make your site experience more efficient 
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Third-party disclosure
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                    We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include cloud server partners and other parties who assist us in operating our app, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.
                                </p>
                                <p>
                                    However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Third-party links
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                    We do not include or offer third-party products or services on our app.
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Google
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
                                We have not enabled Google AdSense on our site but we may do so in the future.

                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      COPPA (Children Online Privacy Protection Act)
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
                                </p>
                                <p>
                                We do not specifically market to children under the age of 13 years old.
                                Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?
    
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Fair Information Practices
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                    The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
                                </p>
                                <p>
                                    <b>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</b>
                                    <li>We will notify you via email within 7 business days</li>
                                    <li>We will notify the users via in-site notification within 1 business day</li>
                                </p>
                                <p>
                                We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
                                </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      CAN SPAM Act
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                    <p>
                        The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
                    </p>
                    <p>
                        <b>We collect your email address in order to:</b>
                        <li>Send information, respond to inquiries, and/or other requests or questions</li>
                        <li>Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</li>
                    </p>
                    <p>
                        <b>To be in accordance with CANSPAM, we agree to the following:</b>
                        <li>Not use false or misleading subjects or email addresses.</li>
                        <li>Identify the message as an advertisement in some reasonable way.</li>
                        <li>Include the physical address of our business or site headquarters.</li>
                        <li>Monitor third-party email marketing services for compliance, if one is used.</li>
                        <li>Honor opt-out/unsubscribe requests quickly.</li>
                        <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
                    </p>
                    <p>
                        <b>If at any time you would like to unsubscribe from receiving future emails, you can email us at:</b>
                    <li>Follow the instructions at the bottom of each email and we will promptly remove you from ALL correspondence.</li>
                    </p>
                  </div>
              </div>

              <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                  <div className="col-lg-3 service-column">
                      <h4 className="title">
                      Contacting Us
                      </h4>
                  </div>
                  <div className="col-lg-9 service-column">
                  <p>
                                If there are any questions regarding this privacy policy, you may contact us using the information below.
                                </p>
                                <p>
                                <b>M8thub Pty Ltd</b><br/>
                                Suite1 Unit 1/285 <br/>
                                Vincent Street Leederville 6007<br/>
                                m8thubapps@gmail.com

                                </p>
                  </div>
              </div>

            </div>

            {/* ----------------------- */}
          </GridItem>
          <GridItem xs={12} sm={12} md={1}></GridItem>
      </GridContainer>

      </div>
  );
}
