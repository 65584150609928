import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// --- Import Images
import image1 from "assets/img/image1.jpg";
import image2 from "assets/img/image2.jpg";
import image3 from "assets/img/image3.jpg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Your new daily communication app</h2>
          <h5 className={classes.description}>
            Chattic combines Instant Messaging, VOIP calls and secured file storage and exchange. The Vault allows you to save images, videos and sensitive documents in your device. These secured files wont be available in your phone's gallery or file folder. You can send these secured files to your contacts in Chattic. With the new Camouflaged Messaging, you can camouflage your secret messages with another message and remove the suspicion that something is hidden.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Instant Messaging"
              description="Send free text messages to your Chattic contacts from documents to Camouflaged and Delayed Messages."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Voice Calls"
              description="You can now make a free voice call to anyone in Chattic. Your calls are secured end-to-end."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="The Vault"
              description="Secure your sensitive pictures, videos and documents. No more leakage when you lost your phone."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>

      {/* ------------------- */}
      {/* --- IMAGE AREAS --- */}
      {/* ------------------- */}
      <div
        style={{
          margin: "120px 0 0" 
        }}
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}
              style={{
                margin: "40px 0"
              }}
            >A new approach in messaging</h2>
            {/* <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              product. Keep you user engaged by providing meaningful information.
              Remember that by this time, the user is curious, otherwise he wouldn
              {"'"}t scroll to get here. Add a button if you want the user to see
              more.
            </h5> */}
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                width: "100%",
                height: 300,
                backgroundColor: "gray",
                background: `url("${image1}")`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                width: "100%",
                height: 300,
                backgroundColor: "gray",
                background: `url("${image2}")`,
                backgroundSize: "cover",
                backgroundPosition: "center right"
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div
              style={{
                width: "100%",
                height: 300,
                backgroundColor: "gray",
                background: `url("${image3}")`,
                backgroundSize: "cover",
                backgroundPosition: "right"
              }}
            />
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}
